import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
// import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

// AMPLIFY
// import { API } from 'aws-amplify';
// import { listCustomers } from '../../../graphql/queries';
import { DataStore } from "@aws-amplify/datastore";
import { UserCustomer } from "../../../models";

// core components
import componentStyles from "assets/theme/components/cards/dashboard/card-team-members.js";
import Person from "@material-ui/icons/Person";

const useStyles = makeStyles(componentStyles);
const customStyles = makeStyles(({
  cardStatsCustom: {
    marginBottom: '1.5rem!important',
  },
  historyContent: {
    padding: "0 2rem 0",
    overflow: 'auto',
    maxHeight: '300px'
  }  
}))


function CardAssignedCustomers(props) {
  const classes = { ...useStyles(), ...customStyles() };
  const theme = useTheme();
  const [customers, setCustomers] = useState([]);
  
  React.useEffect(() => {
    fetchCustomers();
    console.log(customers)
    // const subscription = DataStore.observe(Customer).subscribe((cust) => {
    //   const {name, telephone, email} = cust.element
    //   console.log(name, telephone, email);
    //   if (name)
    //     fetchCustomers()
    // });

    // return () => subscription.unsubscribe();
  }, []);
  
  async function fetchCustomers() {
    let userId = undefined
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData && userData.id) {
      userId = userData.id;
    }
    console.log('Fetch Customers')
    const apiData = await (await DataStore.query(UserCustomer)).filter(u => u.user.id === userId);
    // const apiData = await DataStore.query(Customer);
    console.log('UserCustomer API Data:', apiData)
    setCustomers(apiData.map(d => d.customer));
  }

  const setCustomerView = (customerId) => {
    props.history.push('/admin/customer?id='+customerId)
  }
  

  return (
    <>
      <Card classes={{ root: classes.cardRoot }} elevation={0}>
        <CardHeader
          subheader={"Clientes asignados: " + customers.length}
          subheaderTypographyProps={{
            component: Box,
            variant: "h3",
            marginBottom: "0!important",
            color: "initial",
          }}
        ></CardHeader>
        {/* <CardContent classes={{ root: classes.cardContentRoot }}> */}
        <CardContent classes={{ root: classes.historyContent }}>
          <List disablePadding>
            {customers.map((prop, key) => {
              return (
                <ListItem key={key} className={classes.listItemRoot}>
                  <Grid container component={Box} alignItems="center">
                    <Grid item xs="auto">
                      {/* <Avatar alt="..." src={prop.image} /> */}
                      <Box
                    component={Person}
                    // marginRight=".5em!important"
                  >
                  </Box>
                    </Grid>
                    <Grid item className={classes.gridItemRoot}>
                      <Typography
                        variant="h4"
                        component="h4"
                        className={classes.mb0}
                      >
                        {prop.name + ' ' + (prop.surname ? prop.surname : '')}
                      </Typography>
                      {/* <Box
                        color={theme.palette[prop.color].main}
                        component="span"
                        marginRight=".25rem"
                      >
                        ●
                      </Box>
                      <Box fontSize="80%" fontWeight="400" component="small">
                        {prop.status}
                      </Box> */}
                    </Grid>
                    <Grid item xs="auto">
                      <Button size="small" variant="contained" color="primary"
                        onClick={() => {
                          setCustomerView(prop.id)
                        }}>
                        Ver más
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </>
  );
}

export default CardAssignedCustomers;
