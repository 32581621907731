/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://7qm6korovngphitf4zyjqyo6oe.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ipcumxfqrjbklbpu4avh7uqs34",
    "aws_cognito_identity_pool_id": "eu-west-1:77703666-2fe1-4d76-bfcc-38009a22d9eb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_8nH6fizwy",
    "aws_user_pools_web_client_id": "7d4o4bpctlgfpvvh990u6qjfht",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
