// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Status = {
  "PENDIENTE": "PENDIENTE",
  "EN_PROGRESO": "EN_PROGRESO",
  "FINALIZADO": "FINALIZADO"
};

const UserProfile = {
  "MANAGER": "MANAGER",
  "DOCTOR": "DOCTOR",
  "ADMIN": "ADMIN"
};

const HistoryRecordType = {
  "ESTADO": "ESTADO",
  "CONSEJO": "CONSEJO",
  "SOLICITUD_LLAMADA": "SOLICITUD_LLAMADA",
  "LLAMADA_REALIZADA": "LLAMADA_REALIZADA",
  "SEGUIMIENTO_ACTUALIZADO": "SEGUIMIENTO_ACTUALIZADO"
};

const TipCategory = {
  "GENERAL": "GENERAL",
  "MENTAL": "MENTAL",
  "MEMORIA": "MEMORIA",
  "FISICO": "FISICO",
  "HUMOR": "HUMOR",
  "CONOCIMIENTO": "CONOCIMIENTO",
  "CALCULO": "CALCULO",
  "VOCABULARIO": "VOCABULARIO",
  "HISTORIA": "HISTORIA",
  "GEOGRAFIA": "GEOGRAFIA"
};

const { Contact, FollowUp, User, Customer, History, Tip, UserCustomer } = initSchema(schema);

export {
  Contact,
  FollowUp,
  User,
  Customer,
  History,
  Tip,
  UserCustomer,
  Status,
  UserProfile,
  HistoryRecordType,
  TipCategory
};