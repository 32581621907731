import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "assets/theme/components/headers/customer-header.js";

import Email from "@material-ui/icons/Email";
import Call from "@material-ui/icons/Call";
import Create from "@material-ui/icons/Create";
import Visibility from "@material-ui/icons/Visibility";

const useStyles = makeStyles(componentStyles);

const CustomerHeader = (props) => {
  
  const [customer, setCustomer] = React.useState(props.customer);
  const [view, setView] = React.useState(props.view);
  const [customerName, setCustomerName] = React.useState(props.customerName);
  const [customerSurname, setCustomerSurname] = React.useState(props.customerSurname);
  const [customerOcasoId, setCustomerOcasoId] = React.useState(props.customerOcasoId);

  const setCustomerEditView = (customerId) => {
    props.history.push('/admin/customer-edit?id='+customerId);
  }
  const setCustomerView = (customerId) => {
    props.history.push('/admin/customer?id='+customerId);
  }

  React.useEffect(async () => {

    console.log('CustomerHeader', props)
    
    setCustomer(props.customer)
    setCustomerName(props.customerName)
    setCustomerSurname(props.customerSurname)
    setCustomerOcasoId(props.customerOcasoId)
    
  }, [props]);
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Box
        paddingTop="4.5rem"
        paddingBottom="4.5rem"
        alignItems="center"
        display="flex"
        className={classes.wrapperBox}
        // minHeight="500px"
        position="relative"
        marginBottom={"2em"}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          className={classes.overlayBox}
        />
        <Container
          display="flex"
          alignItems="center"
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid 
            container
            component={Box}
            alignItems="center"
            justifyContent="space-between">
            <Grid item xs="auto">
              <Typography
                variant="h2"
                classes={{ root: classes.typographyRootH1 }}
              >
                {customer.name}{" "}{customer.surname}
              </Typography>
              {( view === 'view' ? 
                <>
                  <Typography
                    variant="h3"
                    classes={{ root: classes.typographyRootH3 }}
                  >
                      <Box
                        component={Call}
                        marginRight=".5em!important"
                      >
                      </Box>
                      {customer.telephone}
                  </Typography>
                  <Typography
                    variant="h3"
                    classes={{ root: classes.typographyRootH3 }}
                  >
                      <Box
                        component={Email}
                        marginRight=".5em!important"
                      >
                      </Box>
                      {customer.email}
                  </Typography>
                </> : ""
              )}
            </Grid>
            <Grid item xs="auto">
              <Box
                justifyContent="flex-end"
                display="flex"
                flexWrap="wrap"
              >
                {( view === 'edit' ? 
                   <Button color="secondary" variant="contained" onClick={() => setCustomerView(customer.id)}>
                    <Box
                      width="1.25rem!important"
                      height="1.25rem!important"
                      marginRight={".5rem"}
                      component={Visibility}
                    />
                    Ver historial
                  </Button> : 
                  <Button color="secondary" variant="contained" onClick={() => setCustomerEditView(customer.id)}>
                    <Box
                      width="1.25rem!important"
                      height="1.25rem!important"
                      marginRight={".5rem"}
                      component={Create}
                    />
                    Editar datos
                  </Button>
                  
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CustomerHeader;
