import React from "react";
// import clsx from "clsx";
// import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
// import Divider from "@material-ui/core/Divider";
// import Hidden from "@material-ui/core/Hidden";
// import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
// @material-ui/icons components
// import Clear from "@material-ui/icons/Clear";
// import Facebook from "@material-ui/icons/Facebook";
// import MenuIcon from "@material-ui/icons/Menu";
// import ShoppingCart from "@material-ui/icons/ShoppingCart";

// core components
import componentStyles from "assets/theme/components/navbars/index-navbar.js";

const useStyles = makeStyles(componentStyles);

export default function OcasoNavbar({forceStyle}) {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "responsive-menu-id";
  const ListLeftObject = (
    <Box
      display="flex"
      alignItems="center"
      width="auto"
      component={List}
      className={classes.flexDirectionColumn}
    >
    </Box>
  );
  
  return (
    <>
      <AppBar
        position="relative"
        color="transparent"
        elevation={0}
        className={forceStyle}
        classes={{ root: classes.ocasoBarRoot }}
      >
        <Toolbar>
          <Container
            display="flex!important"
            justifyContent="space-between"
            alignItems="center"
            component={Box}
            maxWidth="xl"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
            <a
              href="/index"
              rel="noopener noreferrer"
            >
              <Box
                alt="..."
                height="30px"
                component="img"
                marginRight="1rem"
                className={classes.headerImg}
                src={require("assets/img/brand/logo.svg").default}
              />
            </a>
              
            </Box>
            
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
