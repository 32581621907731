import React from "react";
import { API } from 'aws-amplify';
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/icons components

// AMPLIFY
import { Amplify, I18n } from 'aws-amplify';
// import { Amplify } from 'aws-amplify';
import { Authenticator, useTheme, View, Heading, Button, useAuthenticator } from '@aws-amplify/ui-react';
// import { Authenticator, useTheme, View, Image, Text, Heading } from '@aws-amplify/ui-react';
// import { translations } from '@aws-amplify/ui';
import '@aws-amplify/ui-react/styles.css';

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminNavbarAlternative from "components/Navbars/AdminNavbarAlternative.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import componentStyles from "assets/theme/layouts/admin.js";
import CustomerView from "views/admin/Customers/CustomerView";



// Amplify
import awsExports from '../aws-exports';
import { listUsers } from "graphql/queries";
Amplify.configure(awsExports);

// I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabulariesForLanguage('es', {
  'Account recovery requires verified contact information':
    'La recuperación de la cuenta requiere información de contacto verificada',
  'Back to Sign In': 'Volver a inicio de sesión',
  'Change Password': 'Cambiar contraseña',
  'Code': 'Código',
  'Confirm': 'Confirmar',
  'Confirm a Code': 'Confirmar un código',
  'Confirm Password': 'Confirmar contraseña',
  'Confirm Sign In': 'Confirmar inicio de sesión',
  'Confirm Sign Up': 'Confirmar registro',
  'Confirmation Code': 'Código de confirmación',
  'Create a new account': 'Crear una cuenta nueva',
  'Email': 'Email',
  'Forgot Password': 'Olvidé mi contraseña',
  'Forgot your password?': 'Resetear contraseña',
  'Incorrect username or password': 'Nombre de usuario o contraseña incorrecta',
  'Invalid password format': 'Formato de contraseña inválido',
  'Invalid phone number format': 'Formato de número de teléfono inválido',
  'Loading...': 'Cargando...',
  'New Password': 'Nueva contraseña',
  'or': 'o',
  'Password': 'Contraseña',
  'Phone Number': 'Número de teléfono',
  'Resend a Code': 'Reenviar un código',
  'Resend Code': 'Reenviar código',
  'Send Code': 'Enviar código',
  'Sign In': 'Iniciar sesión',
  'Sign in': 'Iniciar sesión',
  'Sign in to your account': 'Iniciar sesión en tu cuenta',
  'Sign In with Amazon': 'Entrar con Amazon',
  'Sign Out': 'Cerrar sesión',
  'Sign Up': 'Crear cuenta',
  Skip: 'Omitir',
  Submit: 'Enviar',
  'User already exists': 'El usuario ya existe',
  'User does not exist': 'El usuario no existe',
  'Username': 'Nombre de usuario',
  'Username cannot be empty': 'El nombre de usuario no puede estar vacío',
  'Verify': 'Verificar',
  'Verify Contact': 'Verificar contacto',
});

import OcasoNavbar from "components/Navbars/OcasoNavbar.js";
import OcasoFooter from "components/Footers/OcasoFooter.js";
import CustomerEdit from "views/admin/Customers/CustomerEdit";

const useStyles = makeStyles(componentStyles);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <OcasoNavbar forceStyle="header-fix"/>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <OcasoFooter forceStyle="footer-fix"/>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.large}`}
          level={5}
          textAlign={'center'}
        >Introduce tu email y contraseña:</Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            margin='1em'
          >
            He olvidado la contraseña
          </Button>
          
        </View>
      );
    },
  },

  // SignUp: {
  //   Header() {
  //     const { tokens } = useTheme();

  //     return (
  //       <Heading
  //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
  //         level={3}
  //       >
  //         Crear una nueva cuenta
  //       </Heading>
  //     );
  //   },
  //   Footer() {
  //     const { toSignIn } = useAuthenticator();

  //     return (
  //       <View textAlign="center">
  //         <Button
  //           fontWeight="normal"
  //           onClick={toSignIn}
  //           size="small"
  //           variation="link"
  //         >
  //           Volver a Log In
  //         </Button>
  //       </View>
  //     );
  //   },
  // },
};

const Admin = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [sidebarOpenResponsive, setSidebarOpenResponsive] = React.useState(
    false
  );
  const [user, setUser] = React.useState({})
  const [signOutFunction, setSignOutFunction] = React.useState({})
  const [userData, setUserData] = React.useState({})
  const [userEmail, setUserEmail] = React.useState(undefined)

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    const queryParams = new URLSearchParams(props.location.search);

    const redirect_uri = queryParams.get("redirect_uri");
    if (redirect_uri) {
      localStorage.setItem('redirect_uri', redirect_uri)
      const state = queryParams.get("state");
      if (state) localStorage.setItem('state', state)
    }
    
  }, [location]);

  React.useEffect(() => {
    // console.log('Recibimos el siguiente email:', userEmail)
    if (userEmail) {
      fetchUserData(userEmail)
    }
  }, [userEmail]);

  async function fetchUserData(email) {
    // console.log('Realizamos query con', email)
    const apiData = await API.graphql({ query: listUsers, 
      variables: {
        filter: {
          email: {
            eq: email
          }
        }
      } 
    });
    console.log(apiData.data.listUsers)
    const userDataAPI = apiData.data.listUsers.items[0];
    // console.log('Datos tras la llamada al API:', userDataAPI)
    if (userDataAPI !== undefined && userDataAPI.id) {
      // console.log('Los datos del usuario en la webapp son:', userDataAPI)
      localStorage.setItem('userData', JSON.stringify(userDataAPI))
      setUserData(userDataAPI)
    }
  }

  const getRoutes = (routes) => {
    const extraRoutes = [
      {
        path: "/customer",
        name: "Vista de cliente",
        component: CustomerView,
        layout: "/admin",
      },
      {
        path: "/customer-edit",
        name: "Edición de cliente",
        component: CustomerEdit,
        layout: "/admin",
      },
    ]
    const newRoutes = [...routes, ...extraRoutes]
    return newRoutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    // <Authenticator socialProviders={['amazon']} components={components}>
    <Authenticator loginMechanisms={['email']} components={components}>
      {({ signOut, user }) => {
        
        if (user) {
          setUser(user)
          {/* setSignOutFunction(signOut) */}
          setUserEmail(user.attributes.email)
          {/* console.log('Obtenemos user:', user) */}
          localStorage.setItem('user', JSON.stringify(user.attributes))
          {/* signin(user.attributes.email, signOut) */}
          const queryParams = new URLSearchParams(props.location.search);
          const code = queryParams.get("code");
          if (code) {
            localStorage.setItem('code', code)
          }
        }
        return (
          <Box display="flex">
            <Sidebar
              routes={routes}
              openResponsive={sidebarOpenResponsive}
              closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
              logo={{
                innerLink: "/index",
                imgSrc: require("../assets/img/brand/ocaso-logo.png").default,
                imgAlt: "...",
              }}
            />
            <Box position="relative" flex="1" className={classes.mainContent}>
              {location.pathname === "/admin/alternative-dashboard" ? (
                <AdminNavbarAlternative
                  openSidebarResponsive={() => setSidebarOpenResponsive(true)}
                />
              ) : (
                <AdminNavbar
                  openSidebarResponsive={() => setSidebarOpenResponsive(true)}
                  userData={userData}
                  signOut={signOut}
                />
              )}
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/admin/dashboard" />
              </Switch>
              <Container
                maxWidth={false}
                component={Box}
                classes={{ root: classes.containerRoot }}
              >
                <AdminFooter />
              </Container>
            </Box>
          </Box>
        )
        
      
      }}
    </Authenticator>
  );
};


export default Admin;
