import React from "react";
// import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import CardHeader from "@material-ui/core/CardHeader";
import Dropzone from "dropzone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
// import Checkbox from "@material-ui/core/Checkbox";
// import Container from "@material-ui/core/Container";
// import Divider from "@material-ui/core/Divider";
// import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormGroup from "@material-ui/core/FormGroup";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
// @material-ui/icons components
import Person from "@material-ui/icons/Person";
import Email from "@material-ui/icons/Email";
import Public from "@material-ui/icons/Public";
import Call from "@material-ui/icons/Call";
// core components


import componentValidationStyles from "assets/theme/views/admin/validation.js";
import componentStylesForms from "assets/theme/components/forms.js";
import componentStyles from "assets/theme/views/admin/components.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesSwitch from "assets/theme/components/switch.js";

const useValidationStyles = makeStyles(componentValidationStyles);
const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesSwitch = makeStyles(componentStylesSwitch);
const useStylesForms = makeStyles(componentStylesForms);

import * as XLSX from "xlsx";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


const CustomerForm = (props) => {
    const {open, openAction, addNewAction, bulkImport} = props
    const classes = { ...useStyles(), ...useStylesForms(), ...useValidationStyles(), ...useStylesButtons(), ...useStylesSwitch() };
    const theme = useTheme();
    const [name, setName] = React.useState(undefined);
    const [email, setEmail] = React.useState(undefined);
    const [telephone, setTelephone] = React.useState(undefined);
    const [preview, setPreview] = React.useState(undefined);
    const [customerContact, setCustomerContact] = React.useState(undefined);
    const [type, setType] = React.useState(props.type || "client");
    
    React.useEffect(() => {
      // console.log('CustomerForm useEffect', props.data)
      if (props.data) {
        setCustomerContact(props.data)
      }
    }, [props]);

    React.useEffect(() => {
      if (customerContact && customerContact.name) {
        const {name, telephone, email} = customerContact
        setName(name)
        setEmail(email)
        setTelephone(telephone)
      }
    }, [customerContact]);

    React.useEffect(() => {
      if (document
        .getElementById("dropzone-single") &&
        !document
          .getElementById("dropzone-single")
          .classList.contains("dz-clickable")
      ) {
        Dropzone.autoDiscover = false;
        // this variable is to delete the previous image from the dropzone state
        // it is just to make the HTML DOM a bit better, and keep it light
        let currentSingleFile = undefined;
        // single dropzone file - accepts only images
        let singleDropzone = new Dropzone(
          document.getElementById("dropzone-single"),
          {
            url: "/",
            thumbnailWidth: null,
            thumbnailHeight: null,
            previewsContainer: document.getElementsByClassName(
              "dz-preview-single"
            )[0],
            previewTemplate: document.getElementsByClassName(
              "dz-preview-single"
            )[0].innerHTML,
            dictDefaultMessage: "Arrastra aquí un fichero de Excel para importar",
            maxFiles: 1,
            // acceptedFiles: "image/*",
            acceptedFiles: "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            init: function () {
              this.on("addedfile", function (file) {
                const accepted = ["text/csv","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
                console.log('Fichero', file)
                if (accepted.includes(file.type)) {
                  const types = {
                    "text/csv": "csv",
                    "application/vnd.ms-excel": "xlml",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx"
                  }
                  console.log('Fichero correcto')
                  console.log('PROCESAMOS!!')
                  
                     
                  const reader = new FileReader() 
                  const rABS = !!reader.readAsBinaryString;  // !! converts object to boolean 
                  reader.onabort = () => console.log('file reading was aborted')
                  reader.onerror = () => console.log('file reading has failed')
                  reader.onload = (e) => {
                      // Do what you want with the file contents 
                      var bstr = e.target.result; 
                      var workbook = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
                      var sheet_name_list = workbook.SheetNames[0];
                      var jsonFromExcel = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list], {
                          raw: false,
                          dateNF: "MM-DD-YYYY",
                          header:1,
                          defval: ""
                      })
                      console.log("jsonFromExcel object=")
                      console.log(jsonFromExcel)
                      console.log("jsonFromExcel string=")
                      console.log(JSON.stringify(jsonFromExcel))
                      sendBulkData(jsonFromExcel)
                    }
                    if (rABS) reader.readAsBinaryString(file);
                    else reader.readAsArrayBuffer(file);
                  
                }
                if (currentSingleFile) {
                  this.removeFile(currentSingleFile);
                }
                currentSingleFile = file;
              });
            },
          }
        );
        document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
        return function cleanup() {
          singleDropzone.destroy();
        };
      }
    }, [open]);

    function sendData(e) {
        e.preventDefault();
        console.log(e)
        if (name && email && telephone) {
            console.log('Tenemos datos', name, email, telephone)
            // TODO: send addNewAction({name, email, telephone})
            addNewAction({name, email, telephone})
            openAction(false)
        } else {
            console.log('NO DATA MESSAGE')
        }
        return false
    }

    function sendBulkData(data) {
        if (data) {
            console.log('Tenemos datos para importar', data)
            // TODO: send addNewAction({name, email, telephone})
            addNewAction(data)
            openAction(false)
        } else {
            console.log('NO DATA MESSAGE')
        }
        return false
    }

    return (
        <>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => openAction(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
      {bulkImport ? 
        (<form onSubmit={sendBulkData}>
        <DialogContent>
        
              <Grid
                container
                component={Box}
                marginRight="-5px!important"
                marginLeft="-5px!important"
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  component={Box}
                  paddingRight="5px!important"
                  paddingLeft="5px!important"
                  marginBottom="1rem!important"
                >
                  <Typography
                      variant="h3"
                      component="h3"
                    >
                      Importar clientes desde un fichero Excel
                  </Typography>
                </Grid>
                <Grid item xs={12} component={Box} textAlign="center">
                  <div
                    className="dropzone dropzone-single mb-3 text-center"
                    id="dropzone-single"
                  >
                    <div className="fallback">
                      <div className="custom-file">
                        <input
                          className="custom-file-input"
                          id="projectCoverUploads"
                          type="file"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="projectCoverUploads"
                        >
                          Elige un fichero
                        </label>
                      </div>
                    </div>
                    <div className="dz-preview dz-preview-single">
                      <div className="dz-preview-cover">
                        <img
                          alt="..."
                          className="dz-preview-img"
                          data-dz-thumbnail=""
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                
                
                
              </Grid>
              {/* <Button color="primary" variant="contained" type="submit" disabled={!name || !email || !telephone}>
                Añadir cliente
              </Button> */}
            
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  color="primary"
                  variant="contained"
                //   onClick={sendData}
                  type="submit" disabled={!name || !email || !telephone}
                >
                  {(customerContact ? "Actualizar contacto" : type === "contact" ? "Añadir contacto" : "Añadir cliente")}
                </Button>
              </Box>
            </Box>
            <Button onClick={() => openAction(false)} color="primary">
              Cerrar
            </Button>
          </Box>
        </DialogActions>
        </form>)
        :
        (<form onSubmit={sendData}>
        <DialogContent>
        
              <Grid
                container
                component={Box}
                marginRight="-5px!important"
                marginLeft="-5px!important"
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  component={Box}
                  paddingRight="5px!important"
                  paddingLeft="5px!important"
                  marginBottom="1rem!important"
                >
                  <FormGroup>
                    <FormLabel>Nombre</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type="text"
                        placeholder={"Nombre del " + (type === "client" ? "cliente" : "contacto")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        }
                      />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  component={Box}
                  paddingRight="5px!important"
                  paddingLeft="5px!important"
                  marginBottom="1rem!important"
                >
                  <FormGroup>
                    <FormLabel>Email</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type="email"
                        placeholder="Correo electrónico"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        }
                      />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  component={Box}
                  paddingRight="5px!important"
                  paddingLeft="5px!important"
                  marginBottom="1rem!important"
                >
                  <FormGroup>
                    <FormLabel>Teléfono</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type="tel"
                        pattern="[0-9]{9}"
                        placeholder="Número de teléfono"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Public />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <Call />
                          </InputAdornment>
                        }
                      />
                  </FormGroup>
                </Grid>
                
              </Grid>
              {/* <Button color="primary" variant="contained" type="submit" disabled={!name || !email || !telephone}>
                Añadir cliente
              </Button> */}
            
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  color="primary"
                  variant="contained"
                //   onClick={sendData}
                  type="submit" disabled={!name || !email || !telephone}
                >
                  {(customerContact ? "Actualizar contacto" : type === "contact" ? "Añadir contacto" : "Añadir cliente")}
                </Button>
              </Box>
            </Box>
            <Button onClick={() => openAction(false)} color="primary">
              Cerrar
            </Button>
          </Box>
        </DialogActions>
        </form>)
      }
        
      </Dialog>
            
          
        
        </>
    )
}


export default CustomerForm;