import React from "react";
import { useLocation } from "react-router-dom";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// import moment from "moment";
// import "moment/locale/es";
import ReactBSAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
// import Badge from "@material-ui/core/Badge";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
// import TextField from "@material-ui/core/TextField";
// @material-ui/lab components
// import Autocomplete from "@material-ui/lab/Autocomplete";
// @material-ui/icons components
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import Flight from "@material-ui/icons/Flight";
// import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
// import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
// import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
// import SentimentNeutralIcon from '@material-ui/icons/SentimentNeutral';
// import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
// import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
// import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// import TrendingUp from "@material-ui/icons/TrendingUp";
// import Code from "@material-ui/icons/Code";
// import Notifications from "@material-ui/icons/Notifications";
// import ThumbUp from "@material-ui/icons/ThumbUp";
import Email from "@material-ui/icons/Email";
import Person from "@material-ui/icons/Person";
import Public from "@material-ui/icons/Public";
import Call from "@material-ui/icons/Call";
import DateRange from "@material-ui/icons/DateRange";
// import Notifications from "@material-ui/icons/Notifications";
// import ThumbUp from "@material-ui/icons/ThumbUp";
// import ThumbDown from "@material-ui/icons/ThumbDown";
// core components
import CustomerHeader from "components/Headers/CustomerHeader.js";
// import CardProfile from "components/Cards/Profile/CardProfile.js";
// import CardStatsGradient from "components/Cards/Widgets/CardStatsGradient.js";
// import CardStats from "components/Cards/Dashboard/CardStats.js";
// import CardProgressTrack from "components/Cards/Dashboard/CardProgressTrack.js";
// import CardFollowUps from "components/Cards/Dashboard/CardFollowUps.js";
// import CardCalls from "components/Cards/Dashboard/CardCalls.js";

import componentStyles from "assets/theme/views/admin/profile.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";
import componentStylesTimeline from "assets/theme/views/admin/timeline.js";
import componentStylesBadge from "assets/theme/components/badge.js";

// Amplify
// import { API } from 'aws-amplify';
// import { getCustomer, listHistories } from "graphql/queries";
// import CustomerTimeline from "./CustomerTimeline";
// import CustomerValuesChart from "./CustomerValuesChart";
// import { listUsers } from "graphql/queries";
// import { listHistories } from "graphql/queries";
import { DataStore } from "@aws-amplify/datastore";
// import { Customer, History, User } from "../../../models";
import { Customer, User } from "../../../models";
import CustomerContactEdit from "./CustomerContactEdit";

// Styles
const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);
const useStylesTimeline = makeStyles(componentStylesTimeline);
const useStylesBadge = makeStyles(componentStylesBadge);

const customStyles = makeStyles(({
  cardStatsCustom: {
    marginBottom: '1.5rem!important',
  },
  historyContent: {
    overflow: 'auto',
    maxHeight: '600px'
  }  
}))

function CustomerEdit(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(props.location.search);
  const [customerId, setCustomerId] = React.useState('');
  const [customer, setCustomer] = React.useState({});
  // const [history, setHistory] = React.useState([]);

  const [alert, setAlert] = React.useState(null);
  

  const [birthdate, setBirthdate] = React.useState(new Date());
  const [customerName, setCustomerName] = React.useState(undefined);
  const [customerSurname, setCustomerSurname] = React.useState(undefined);
  const [customerAlias, setCustomerAlias] = React.useState(undefined);
  const [customerEmail, setCustomerEmail] = React.useState(undefined);
  const [customerPhone, setCustomerPhone] = React.useState(undefined);
  

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    console.log('CustomerView', props)

    setCustomerId(queryParams.get("id"));
    console.log('customerId', customerId);

    fetchCustomer(queryParams.get("id"));
    
  }, [location]);

  // React.useEffect(() => {
  //   if (users && users.length > 0 
  //     && customer.users && customer.users.items 
  //     && customer.users.items[0]) {
  //     const {userID} = customer.users.items[0]
  //     const currentUserData = users.find(u => u.value === userID)
  //     if (currentUserData) {
  //       console.log(currentUserData)
  //       setUserIdSelected(currentUserData)
  //     }
  //   }
  //   if (customer.Contacts && customer.Contacts.items) {
  //     const contact = customer.Contacts.items[0]
  //     if (contact && contact.name) {
  //       setCustomerContact(contact)
  //     }
  //   }
  // }, [customer, users])

  const handleReactDatetimeChange = (who, date) => {
    console.log(who, date)
    setBirthdate(new Date(date))
  };


  async function fetchCustomer(customerId) {
    console.log("Customer ID", customerId);
    const apiData = await DataStore.query(Customer, customerId);
    // const apiData = await API.graphql({ query: getCustomer, variables: {id: customerId} });
    // const customerData = apiData.data.getCustomer;
    const customerData = apiData;
    setCustomer(customerData);
    setCustomerName(customerData.name);
    setCustomerSurname(customerData.surname);
    setCustomerAlias(customerData.alias);
    setCustomerEmail(customerData.email);
    setCustomerPhone(customerData.telephone);
    const bd = new Date(customerData.birthdate)
    setBirthdate(bd)
    console.log('Customer:', customer);
    return customerData;
  }

  async function updateCustomer(customerData) {
    // console.log('updateCustomer input:', customerData)
    const { id, email, telephone, name, surname, NIF, birthdate, alias  } = customerData
    console.log('Update con:',{ id, email, telephone, name, surname, NIF, birthdate, alias })
    const original = await DataStore.query(Customer, id);
    const result = await DataStore.save(
      Customer.copyOf(original, updated => {
        if (birthdate.split("T")[0] !== original.birthdate)
          updated.birthdate = birthdate.split("T")[0]
        if (name !== original.name) updated.name = name
        if (surname !== original.surname) updated.surname = surname
        if (alias !== original.alias) updated.alias = alias
        if (email !== original.email) updated.email = email
        if (telephone !== original.telephone) updated.telephone = telephone
      })
    );
    if (result) {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Hecho"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          ¡Cliente actualizado correctamente!
        </ReactBSAlert>
      );
      return result
    }
    return undefined
  }

  async function fetchUsers() {
    // const apiData = await API.graphql({ query: listUsers });
    const apiData = await DataStore.query(User);
    const userList = []
    // apiData.data.listUsers.items.forEach(c => {
    apiData.forEach(c => {
      userList.push(
        {
          value: c.id,
          label: `${c.name} ${c.surname}`
        }
      )
    })
    console.log(userList)
    setUsers(userList);
  }

  



  const classes = { ...useStyles(), ...useStylesCardImg(), ...useStylesTimeline(), ...useStylesBadge(), ...customStyles() };
  const theme = useTheme();

  return (
    <>
      {alert}
      <CustomerHeader customer={customer} history={props.history} view={"edit"}/>
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>

          {/* Timeline del histórico */}
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                {/* Información del perfil */}
            <Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Datos del cliente
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            updateCustomer({
                              id: customerId,
                              name: customerName,
                              surname: customerSurname,
                              email: customerEmail,
                              telephone: customerPhone,
                              alias: customerAlias,
                              birthdate: birthdate.toISOString()
                            })
                          }}
                        >
                          Guardar cambios
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  Cliente
                </Box>
                <div>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Nombre</FormLabel>
                        <OutlinedInput
                        fullWidth
                        type="text"
                        placeholder="Nombre"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        }
                      />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Apellidos</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={OutlinedInput}
                            autoComplete="off"
                            type="text"
                            onChange={(e) => setCustomerSurname(e.target.value)}
                            value={customerSurname}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Alias</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={OutlinedInput}
                            autoComplete="off"
                            type="text"
                            onChange={(e) => setCustomerAlias(e.target.value)}
                            value={customerAlias}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>Fecha de nacimiento</FormLabel>
                      <ReactDatetime
                        inputProps={{
                          placeholder: "Date Picker Here",
                          className: "",
                        }}
                        value={birthdate}
                        timeFormat={false}
                        locale="es"
                        dateFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          handleReactDatetimeChange("startDate", e)
                          // setBirthdate(new Date(e.target.value))
                        }
                        }
                        renderDay={(props, currentDate) => {
                          let classes = props.className;
                          classes = 'start-date';
                          return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                          );
                        }}
                        renderInput={(dateInputProps) => (
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <OutlinedInput
                              {...dateInputProps}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box
                                    component={DateRange}
                                    marginRight=".5rem"
                                  />
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        )}
                      />
                      
                    </FormGroup>
                  </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  Información de contacto
                </Box>
                <div>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <OutlinedInput
                            fullWidth
                            type="email"
                            placeholder="Dirección de email"
                            value={customerEmail}
                            onChange={(e) => setCustomerEmail(e.target.value)}
                            startAdornment={
                              <InputAdornment position="start">
                                <Email />
                              </InputAdornment>
                            }
                          />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormLabel>Número de teléfono</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          placeholder="Número de telefono"
                          value={customerPhone}
                          onChange={(e) => setCustomerPhone(e.target.value)}
                          startAdornment={
                            <InputAdornment position="start">
                              <Public />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <Call />
                            </InputAdornment>
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  
                </div>
                
              </CardContent>
            </Card>
              </Grid>
              
              
            </Grid>
            
          </Grid>

          {/* Perfil del usuario */}
          <Grid
            item
            xs={12}
            lg={6}
            component={Box}
            marginBottom="3rem"
            paddingBottom="3rem"
            // classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <CustomerContactEdit {...props} type={"users"} customer={customer} />
            <CustomerContactEdit {...props} />

          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default CustomerEdit;
