import React from "react";
import { useLocation } from "react-router-dom";
// react plugin used to create datetimepicker
// import ReactDatetime from "react-datetime";
// import moment from "moment";
// import "moment/locale/es";
import ReactBSAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
// import Badge from "@material-ui/core/Badge";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// import Container from "@material-ui/core/Container";
// import Divider from "@material-ui/core/Divider";
// import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
// import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
// @material-ui/lab components
import Autocomplete from "@material-ui/lab/Autocomplete";
// @material-ui/icons components
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import Flight from "@material-ui/icons/Flight";
// import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
// import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
// import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
// import SentimentNeutralIcon from '@material-ui/icons/SentimentNeutral';
// import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
// import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// import TrendingUp from "@material-ui/icons/TrendingUp";
// import Code from "@material-ui/icons/Code";
// import Notifications from "@material-ui/icons/Notifications";
// import ThumbUp from "@material-ui/icons/ThumbUp";
import Email from "@material-ui/icons/Email";
import Person from "@material-ui/icons/Person";
// import Public from "@material-ui/icons/Public";
import Call from "@material-ui/icons/Call";
// import DateRange from "@material-ui/icons/DateRange";
// import Notifications from "@material-ui/icons/Notifications";
// import ThumbUp from "@material-ui/icons/ThumbUp";
// import ThumbDown from "@material-ui/icons/ThumbDown";
// core components
// import CustomerHeader from "components/Headers/CustomerHeader.js";
// import CardProfile from "components/Cards/Profile/CardProfile.js";
// import CardStatsGradient from "components/Cards/Widgets/CardStatsGradient.js";
// import CardStats from "components/Cards/Dashboard/CardStats.js";
// import CardProgressTrack from "components/Cards/Dashboard/CardProgressTrack.js";
// import CardFollowUps from "components/Cards/Dashboard/CardFollowUps.js";
// import CardCalls from "components/Cards/Dashboard/CardCalls.js";

import componentStyles from "assets/theme/views/admin/profile.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";
import componentStylesTimeline from "assets/theme/views/admin/timeline.js";
import componentStylesBadge from "assets/theme/components/badge.js";


import CustomerForm from "./CustomerForm";

// Amplify
// import { API } from 'aws-amplify';
// import { getCustomer, listHistories } from "graphql/queries";
// import CustomerTimeline from "./CustomerTimeline";
// import CustomerValuesChart from "./CustomerValuesChart";
// import { listUsers } from "graphql/queries";
// import { listHistories } from "graphql/queries";
import { DataStore } from "@aws-amplify/datastore";
// import { Customer, History, User } from "../../../models";
// import { Customer, User, Contact } from "../../../models";
import { User, Contact, Customer, UserCustomer } from "../../../models";

// Styles
const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);
const useStylesTimeline = makeStyles(componentStylesTimeline);
const useStylesBadge = makeStyles(componentStylesBadge);

const customStyles = makeStyles(({
  cardStatsCustom: {
    marginBottom: '1.5rem!important',
  },
  historyContent: {
    overflow: 'auto',
    maxHeight: '600px'
  }  
}))

function CustomerContactEdit(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(props.location.search);
  const [customerId, setCustomerId] = React.useState('');
  const [customer, setCustomer] = React.useState(props.customer);
  const [customerUsers, setCustomerUsers] = React.useState([]);
  const [modalAdd, setModalAdd] = React.useState(false)
  const [modalEdit, setModalEdit] = React.useState(false)

  const [alert, setAlert] = React.useState(false)
  
  const [customerContact, setCustomerContact] = React.useState(undefined);

  const [users, setUsers] = React.useState([]);
  const [userIdSelected, setUserIdSelected] = React.useState({value: "", label: ""});

  const [type, setType] = React.useState(props.type || "contact");
  

  React.useEffect(() => {
    
    console.log('CustomerContactEdit', props);

    setCustomerId(queryParams.get("id"));
    console.log('customerId', customerId);
    
  }, [location]);

  React.useEffect(() => {
    
    if (type === "contact") {
      fetchContact();
    } else {
      fetchCustomer()
      fetchUsers();
    }
    
  }, [customerId]);

  

  async function fetchCustomer() {
    const apiData = await DataStore.query(Customer, customerId);
    setCustomer(apiData)
    console.log('Customer -->', apiData)
    await fetchUserCustomers(apiData)
  }

  async function fetchUserCustomers(customerData) {
    
    // const cust = await DataStore.query(Customer, customerId);
    const apiData = await (await DataStore.query(UserCustomer)).filter(u => u.customer.id === customerId);
    // const apiData = await DataStore.query(UserCustomer);
    console.log('Customer users -->', apiData)
    setCustomerUsers(apiData)
    if (apiData[0]) {
      const userAssigned = apiData[0].user
      console.log('Usuario asignado', userAssigned)
      const userAssignedToForm = {
        value: userAssigned.id,
        label: `${userAssigned.name} ${(userAssigned.surname || '')}`
      }
      console.log('Usuario asignado para autocomplete', userAssignedToForm)
      setUserIdSelected(userAssignedToForm)
    }
    
  }

  async function fetchUsers() {
    // const apiData = await API.graphql({ query: listUsers });
    const apiData = await DataStore.query(User);
    console.log('Usuarios ---->', apiData)
    const userList = []
    // apiData.data.listUsers.items.forEach(c => {
    apiData.forEach(c => {
      userList.push(
        {
          value: c.id,
          label: `${c.name} ${c.surname}`
        }
      )
    })
    console.log(userList)
    setUsers(userList);
  }

  async function fetchContact() {
    // const apiData = await API.graphql({ query: listUsers });
    const apiData = await DataStore.query(Contact, (c) => c.customerID("eq", customerId));
    console.log('Contacto para el cliente', customerId, "es", apiData)
    if (apiData[0] && apiData[0].id) {
      setCustomerContact(apiData[0]);
    }
  }

  async function createContact(data) {
    console.log('createContact', data)
    const {email, name, telephone} = data
    try {
      const newContact = new Contact({
        email, 
        name, 
        telephone, 
        customerID: customerId
      })
      console.log('Nuevo contacto', newContact)
      const result = await DataStore.save(
        newContact
      );
      console.log("Contacto guardado correctamente!", result);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Hecho"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          ¡Contacto creado correctamente!
        </ReactBSAlert>
      );
      await fetchContact()
      return result
    } catch (error) {
      console.log("Error guardando contacto", error);
      return false
    }
  }

  async function editContact(data) {
    console.log('editContact', data)
    const {email, name, telephone} = data
    try {
      const original = await DataStore.query(Contact, customerContact.id);
      const result = await DataStore.save(
        Contact.copyOf(original, updated => {
          updated.name = name;
          updated.email = email;
          updated.telephone = telephone;
        })
      );
      console.log("Contacto guardado correctamente!", result);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Hecho"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          ¡Contacto actualizado correctamente!
        </ReactBSAlert>
      );
      await fetchContact()
      return result
    } catch (error) {
      console.log("Error guardando contacto", error);
      return false
    }
  }

  async function asignUser(data) {
    console.log('asignUser', data)
    const {value, label} = data
    if (value !== "") {
      try {
        for (const cu of customerUsers) {
          let resultDelete = await DataStore.delete(cu);
          console.log("Usuario desasignado correctamente!", resultDelete);
        }
        const userToAssign = await DataStore.query(User, value)
        console.log('Usuario a asignar', userToAssign)
        const newUserCustomer = new UserCustomer({
          user: userToAssign,
          customer: customer
        })
        const result = await DataStore.save(
          newUserCustomer
        );
        console.log("Usuario asignado correctamente!", result);
        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Hecho"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            ¡Gestor asignado correctamente!
          </ReactBSAlert>
        );
        await fetchUserCustomers()
        return result
      } catch (error) {
        console.log("Error guardando contacto", error);
        return false
      }
    } 
  }

  



  const classes = { ...useStyles(), ...useStylesCardImg(), ...useStylesTimeline(), ...useStylesBadge(), ...customStyles() };
  const theme = useTheme();

  return (
    <>
      {alert}
      {/* Información del contacto en texto */}
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  {type === "contact" ? "Persona de contacto" : "Gestores"}
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box
                  justifyContent="flex-end"
                  display="flex"
                  flexWrap="wrap"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      if (type === "contact") {
                        if (customerContact) {
                          setModalEdit(true)
                        } else {
                          setModalAdd(true)
                        }
                      } else {
                        if (userIdSelected.value !== "") {
                          asignUser(userIdSelected)
                        }
                      }
                    }}
                  >
                    {type === "contact" ? customerContact ? 'Editar' : 'Asignar nuevo contacto' : "Actualizar"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          
          <div>
          {(type === "contact" ? customerContact ? 
            <Box textAlign="left">
              <Typography variant="h3">
              <Box
                  component={Person}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  marginRight=".5rem"
                ></Box>
                {customerContact.name + ' ' + (customerContact.surname ? customerContact.surname : "")}
              </Typography>
              <Box
                component={Typography}
                variant="h5"
                fontWeight="300!important"
                display="flex"
                alignItems="center"
                justifyContent="left"
              >
                <Box
                  component={Email}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  marginRight=".5rem"
                ></Box>
                {customerContact.email}
              </Box>
              <Box
                component={Typography}
                variant="h5"
                fontWeight="300!important"
                display="flex"
                alignItems="center"
                justifyContent="left"
              >
                <Box
                  component={Call}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  marginRight=".5rem"
                ></Box>
                {customerContact.telephone}
              </Box>
              
            </Box> 
            : 
            <Box
                component={Typography}
                variant="h5"
                fontWeight="300!important"
                display="flex"
                alignItems="center"
                justifyContent="left"
              >
              <Box
                  component={Person}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  marginRight=".5rem"
                ></Box>
                No tiene una persona de contacto asignada
            </Box>
            : "")}
          {
            (type === "users" ? 
            <>
              <Box
                component={Typography}
                variant="h6"
                color={theme.palette.gray[600] + "!important"}
                paddingTop=".25rem"
                paddingBottom=".25rem"
                fontSize=".75rem!important"
                letterSpacing=".04em"
                marginBottom="1.5rem!important"
                classes={{ root: classes.typographyRootH6 }}
              >
                Gestor de soledad
              </Box>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <FormGroup>
                      {/* <FormLabel>Gestor asignado</FormLabel> */}
                      <Autocomplete
                        id="combo-box-demo"
                        value={userIdSelected}
                        onChange={(e, value) => {
                          setUserIdSelected(value)
                          console.log('gestor seleccionado', value)
                        }}
                        options={users}
                        getOptionLabel={(option) => option.label}
                        closeIcon={null}
                        popupIcon={
                          <Box
                            component={KeyboardArrowDown}
                            width="1.25rem!important"
                            height="1.25rem!important"
                          />
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gestor asignado"
                            variant="outlined"
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
            </> : ""
            )
          }
          </div>
          
        </CardContent>
      </Card>
      <CustomerForm open={modalAdd} openAction={setModalAdd} addNewAction={async (data) => await createContact(data)} type={"contact"} />
      <CustomerForm open={modalEdit} openAction={setModalEdit} addNewAction={async (data) => await editContact(data)} type={"contact"} data={customerContact} />
    </>
  );
}

export default CustomerContactEdit;
