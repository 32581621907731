import React from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
// import moment from "moment";
// import "moment/locale/es";
import ReactBSAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
// import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FilledInput from "@material-ui/core/FilledInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Slide from "@material-ui/core/Slide";
import { Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";

// AMPLIFY
// import { Hub } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions';
import { DataStore } from "@aws-amplify/datastore";
import { History, HistoryRecordType } from "../../../models";

// core components
import componentStyles from "assets/theme/components/cards/dashboard/card-to-do-list.js";
// import { listHistories } from "graphql/queries";
import Call from "@material-ui/icons/Call";
// import Create from "@material-ui/icons/Create";
// import Add from "@material-ui/icons/Add";
import Notes from "@material-ui/icons/Notes";
import Check from "@material-ui/icons/Check";

const customStyles = makeStyles(({
  cardStatsCustom: {
    marginBottom: '1.5rem!important',
  },
  historyContent: {
    padding: 0,
    overflow: 'auto',
    maxHeight: '300px'
  }  
}))

const useStyles = makeStyles(componentStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


function CardCalls(props) {
  const classes = { ...useStyles(), ...customStyles() };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [alert, setAlert] = React.useState(null);
  
  const [history, setHistory] = React.useState(props.data);
  
  const [state, setState] = React.useState([]);
  const [customerId, setCustomerId] = React.useState(undefined);

  const [modalAdd, setModalAdd] = React.useState(false);
  const [eventDescription, setEventDescription] = React.useState('');

  const setCustomerView = (customerId) => {
    props.history.push('/admin/customer?id='+customerId);
  }

  async function fetchHistory(userId, data) {
    
    if (!userId) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData && userData.id) {
        userId = userData.id;
      }
    }
    console.log('fetchHistory for Calls', userId);
    let today = new Date();
    // const todayString = today.toISOString().split('T')[0];

    if (userId !== '') {
        // let apiData = await DataStore.query(History, (h) => h.type('eq', HistoryRecordType.SOLICITUD_LLAMADA) && h.createdAt("contains", todayString));
        let data = await DataStore.query(History, (h) => h.type('eq', HistoryRecordType.SOLICITUD_LLAMADA));

        let callsData = []
        
        let items = data
        if (customerId) {
          console.log('Filtramos llamadas para el cliente', customerId)
          items = items.filter(i => (i.Customer && i.Customer.id === customerId));
        }
        items.forEach( h => {
            const {id, createdAt, status, reason, notes, description, value, Customer, _version, _deleted} = h
            console.log('Registro', h)
            const currentDate = new Date(createdAt);
            // if (!_deleted 
            //     && ( (currentDate.getTime() >= today.getTime()) || createdAt.includes(todayString)) ) {
              
                callsData.push({
                  id,
                  createdAt,
                  datetime: currentDate.toLocaleString(),
                  description: reason,
                  color: 'error',
                  value,
                  notes,
                  name: description,
                  status,
                  customer: Customer.name + " " + Customer.surname,
                  customerId: Customer.id,
                  _version,
              })
            // }
        })
        callsData.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        setState(callsData)
        
    }
  }

  async function createHistoryRecordMutation(inputData) {
    console.log('createHistoryRecordMutation input:', inputData)
    let userId = undefined;
    if (!userId) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData && userData.id) {
        userId = userData.id;
      }
    }
    const { note, customerId, followUpId } = inputData
    const newNote = new History({
      value: -1,
      type: HistoryRecordType.LLAMADA_REALIZADA,
      description: note,
      historyCustomerId: customerId,
      historyUserId: userId
    })
    const apiData = await DataStore.save(newNote);
    console.log('createHistoryRecordMutation:', apiData)
    if (apiData) {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Hecho"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          ¡Registro de llamada guardado correctamente!
        </ReactBSAlert>
      );
      return apiData
    }
    return undefined
  }

  async function createCallHistoryRecordMutation(inputData) {
    console.log('createHistoryRecordMutation input:', inputData)
    const { note, customerId, followUpId } = inputData
    const newHistRecord = new History({
      value: -1,
      type: 'LLAMADA_REALIZADA',
      description: note,
      historyCustomerId: customerId,
      // historyFollowUpId: followUpId
    })
    const result = await DataStore.save(newHistRecord);
    console.log('createHistoryRecordMutation:', result)
    if (result) {
      return result
    }
    return undefined
  }

  const confirmCallComplete = (call) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Confirmas que se ha dado seguimiento a esta llamada?"
        onConfirm={() => {
          setAlert(false);
        }}
        onCancel={async () => await completeCall(call)}
        confirmBtnCssClass="btn-secondary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Cancelar"
        cancelBtnText="Sí, confirmar"
        showCancel
        btnSize=""
      >
        Esta acción no se puede deshacer...
      </ReactBSAlert>
    );
  };
  
  async function completeCall(call) {
    console.log('Actualizamos llamada', call)
    const original = await DataStore.query(History, call.id);
    const result = await DataStore.save(
      History.copyOf(original, updated => {
        updated.value = 0
      })
    );
    await createCallHistoryRecordMutation({
      note: 'Llamada realizada por el cliente en la fecha del ' + new Date(original.createdAt).toLocaleString() + ', ha sido marcada como completada.',
      customerId
    })
    if (result) {
      fetchHistory(customerId)
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Hecho"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          ¡Llamada completada!
        </ReactBSAlert>
      );
      return result
    }
    return undefined
  }

  async function registerCall() {
    const createRecordResult = await createHistoryRecordMutation({ note: eventDescription, customerId })
    console.log('[registerCall] Registro de histórico creado', createRecordResult)
    setModalAdd(false)
    await fetchHistory()
  }

  React.useEffect(() => {
    if (props.data && props.data.length > 0) {
      setState(props.data.filter(h => h.type === "SOLICITUD_LLAMADA"))
    }
  }, [props.data]);

  React.useEffect(() => {
    if (props.data && props.data.length > 0) {
      setState(props.data.filter(h => h.type === "SOLICITUD_LLAMADA"))
    } else {
      fetchHistory();
    }
  }, [customerId]);

  React.useEffect(() => {
    
    console.log('CustomerCalls', props)

    setCustomerId(queryParams.get("id"))
    console.log('CardCalls customerId', customerId);
    
    
  }, [location]);

  // React.useEffect(() => {
    // const subscription = DataStore.observe(History).subscribe((fu) => {
    //   console.log('Subscription result on CardCalls', fu)
    //   const { id } = fu.element
    //   // if (id)
    //     fetchHistory()
    // });

    // return () => subscription.unsubscribe();
    
  // })

  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onCreateHistory)
    ).subscribe({
        next: async ({ provider, value }) => {
          DataStore.clear().then(() => {
            DataStore.start().then(() => {
              setTimeout(() => {
                fetchHistory(customerId)
              }, 2000)
            })
          })
          
          console.log({ provider, value })
        },
        error: error => console.warn(error)
    });

    return () => subscription.unsubscribe();
  })

  return (
    <>
      {alert}
      <Card classes={{ root: classes.cardRoot }} elevation={0}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box
                    component={Call}
                    marginRight=".5em!important"
                  >
                  </Box>
                  Llamadas solicitadas: {state.length}
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box
                  justifyContent="flex-end"
                  display="flex"
                  flexWrap="wrap"
                >
                  {((customerId) ?
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setModalAdd(true)
                    }}
                  >
                  <Box
                    component={Notes}
                    marginRight=".2em!important"
                  >
                  </Box>
                    Añadir nota
                  </Button> : ""
                )}
                </Box>
              </Grid>
            </Grid>
          }
          subheaderTypographyProps={{
            component: Box,
            variant: "h3",
            marginBottom: "0!important",
            color: "initial",
          }}
        ></CardHeader>
        <CardContent classes={{ root: classes.historyContent }}>
          <List disablePadding>
            {state.map((prop, key) => {
              return (
                <ListItem key={key} className={classes.listItemRoot}>
                  <Grid container component={Box} alignItems="center">
                    <Grid
                      item
                      className={clsx(
                        classes.gridItemRoot,
                        // classes[prop.color + "GridItemRoot"]
                        classes[(prop.value < 0 ? "error" : "success") + "GridItemRoot"]
                      )}
                    >
                      <Typography
                        variant="h5"
                        component="h5"
                        className={clsx(classes.mb0, {
                          // [classes.lineThrough]: prop.checked,
                        })}
                      >
                        {prop.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={clsx(classes.mb0, {
                          // [classes.lineThrough]: prop.checked,
                        })}
                      >
                        {/* {prop.description} */}
                      </Typography>
                      <Box
                        fontSize="80%"
                        fontWeight="400"
                        component="small"
                        className={clsx({
                          // [classes.lineThrough]: prop.checked,
                        })}
                      >
                        {prop.datetime} - {prop.customer}
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      {(props.customerId ? 
                        (prop.value === -1 ? 
                      <Tooltip title="Completar llamada">
                        <IconButton aria-label="edit"
                              onClick={() => {
                                confirmCallComplete(prop)
                              }
                            }
                          >
                          <Box
                            component={Check}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            color="primary"
                          />
                        </IconButton>
                      </Tooltip> : '')
                      : 
                      <Button size="small" variant="contained" color="primary"
                        onClick={() => {
                          setCustomerView(prop.customerId)
                        }}>
                        Ver cliente
                      </Button>
                      )}
                      {/* <Checkbox
                        checked={prop.checked}
                        onChange={() => {
                          let newState = state.map((stateProp, stateKey) => {
                            if (key === stateKey) {
                              return {
                                key: stateKey,
                                ...stateProp,
                                checked: !prop.checked,
                              };
                            }
                            return stateProp;
                          });
                          setState(newState);
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        classes={{ checked: classes[prop.color + "Checkbox"] }}
                      /> */}
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>


      <Dialog
        open={modalAdd}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalAdd(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          
          <FormGroup>
            <FormLabel>Notas de llamada a cliente</FormLabel>
          </FormGroup>
          
          <FormGroup>
            <FormLabel>Notas</FormLabel>
            <FilledInput
              placeholder="Notas sobre la llamada"
              type="text"
              multiline
              rows="6"
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
            />
          </FormGroup>
          
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              <Button color="primary" variant="contained" onClick={registerCall}>
                Registrar
              </Button>
            </Box>
            <Button onClick={() => setModalAdd(false)} color="primary">
              Cancelar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CardCalls;
