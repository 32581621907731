import React from "react";
// import { Redirect } from "react-router-dom";
// react plugin that prints a given react component
// import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// @material-ui/lab components
// @material-ui/icons components

// AMPLIFY
import { DataStore } from "@aws-amplify/datastore";
import { Customer, FollowUp, User, UserCustomer } from "../../../models";
// import { API } from 'aws-amplify';
// import { listCustomers } from '../../../graphql/queries';


// core components
import TipsHeader from "components/Headers/TipsHeader.js";

import componentStyles from "assets/theme/views/admin/react-bs-table.js";
import componentProgressStyles from "assets/theme/components/cards/alternative/card-progress-track-alternative.js";
import CustomerForm from "./CustomerForm";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);
const useProgressStyles = makeStyles(componentProgressStyles);

const sample = arr => arr[Math.floor(Math.random() * arr.length)];

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total"> Mostrando del { from } al { to } de { size } clientes en total.</span>
);
const customSimpleTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total"> { from } a { to } de { size }.</span>
);
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  paginationTotalRenderer: customTotal,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        clientes.
      </label>
    </div>
  ),
});

const simplePagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  paginationTotalRenderer: customSimpleTotal,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        clientes.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const CustomerList = (props) => {
  const classes = { ...useStyles(), ...useProgressStyles() };
  const [customers, setCustomers] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [modalAdd, setModalAdd] = React.useState(false); // Add modal
  const [modalImport, setModalImport] = React.useState(false); // Add modal
  const [importProgress, setImportProgress] = React.useState(0); // Add modal
  const [alert, setAlert] = React.useState(null);
  // const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file

  React.useEffect(() => {
    fetchCustomers();
    fetchUsers();
    console.log(customers)
    // const subscription = DataStore.observe(Customer).subscribe((cust) => {
    //   const {name, telephone, email} = cust.element
    //   console.log(name, telephone, email);
    //   if (name)
    //     fetchCustomers()
    // });

    // return () => subscription.unsubscribe();
    
  }, []);

  async function fetchUsers() {
    const apiData = await DataStore.query(User);
    // console.log('Usuarios ---->', apiData)
    setUsers(apiData);
  }

  async function fetchCustomers() {
    console.log('Fetch Customers')
    const apiData = await DataStore.query(Customer);
    console.log('API Data:', apiData)
    setCustomers(apiData.map(c => {  return Customer.copyOf(c, updated => {
      updated.surname = c.surname ? c.surname : '';
    }) }));
  }

  async function asignUser(customer, userId) {
    console.log('asignUser', userId)
    try {
      const userToAssign = await DataStore.query(User, userId)
      console.log('Usuario a asignar', userToAssign)
      const newUserCustomer = new UserCustomer({
        user: userToAssign,
        customer: customer
      })
      const result = await DataStore.save(
        newUserCustomer
      );
      console.log("Usuario asignado correctamente!", result);
      return result
    } catch (error) {
      console.log("Error guardando contacto", error);
      return false
    }
  }

  async function createCustomer(data, isBulk = false) {
    console.log('Create Customer with', data)
    try {
      const newCustomer = new Customer({
        name: data.name,
        telephone: data.telephone,
        email: data.email
      })
      console.log('Nuevo cliente', newCustomer)
      const result = await DataStore.save(
        newCustomer
      );
      console.log("Customer guardado correctamente!", result);
      const userId = sample(users.filter(u => u.profile === "MANAGER")).id
      if (userId) {
        await asignUser(result, userId)
      }
      await fetchCustomers()
      for (let i = 0; i < 12; i++) {
        let newFollowUp = new FollowUp({
          status: "PENDIENTE",
          reason: "Seguimiento " + (i+1),
          followUpUserId: userId,
          followUpCustomerId: result.id
        })
        let result2 = await DataStore.save(
          newFollowUp
        );
        console.log("Seguimiento creado correctamente!", result2);       
      }
      if (!isBulk)
        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Hecho"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            ¡Cliente creado correctamente!
          </ReactBSAlert>
      );
    } catch (error) {
      console.log("Error guardando cliente", error);
      return false
    }
    return true
  }

  async function importCustomers(data) {
    console.log('import customers', data)
    const newCustomers = []
    for (const newCustomer of data) {
      if (newCustomer[0] && newCustomer[0] !== 'nombre') {
        // nombre, email, teléfono
        newCustomers.push({
          name: newCustomer[0],
          email: newCustomer[1],
          telephone: newCustomer[2],
        })
      }
    }

    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    const publishCustomers = async (newCustomers) => {
      setAlert(
        <ReactBSAlert
          info
          style={{ display: "block", marginTop: "-100px" }}
          title="Importando clientes"
          onConfirm={() => null}
          onCancel={() => null}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="warning"
          confirmBtnText="Sí, dar de alta"
          showConfirm={false}
          btnSize=""
        >
          Espere, por favor...
          <br/>
          <LinearProgress
            variant="indeterminate"
            // value={importProgress}
            classes={{
              // root: classes.linearProgressRoot,
              bar: classes['success' + "LinearProgress"],
            }}
          />
          <br/>
        </ReactBSAlert>
      )
      let i = 0
      for (const newCustomer of newCustomers) {
        i++
        if (newCustomer.name && newCustomer.name !== 'nombre') {
          
          // Update progress
          console.log('Alta con con', newCustomer)
          // let progress = (i/newCustomers.length)*100
          // console.info('Progreso:', progress)
          // setImportProgress(progress)
          // await sleep(2000);
          // nombre, email, teléfono
          try {
            await createCustomer(newCustomer, true)
          } catch(ex) {
            console.error(ex)
            setAlert(null)
          }
          
        }
      }
      setAlert(null)
    }

    setAlert(
      <ReactBSAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title="Confirmación"
        onConfirm={async () => await publishCustomers(newCustomers)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="warning"
        confirmBtnText="Sí, dar de alta"
        cancelBtnText="Cancelar"
        showCancel
        btnSize=""
      >
        ¿Quieres dar de alta los siguientes clientes?
        <ToolkitProvider
                data={newCustomers}
                keyField="id"
                columns={[
                  {
                    dataField: "name",
                    text: "Nombre",
                    sort: true,
                  },
                  {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                  },
                  {
                    dataField: "telephone",
                    text: "Teléfono",
                    sort: true,
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive table-click">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={simplePagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
      </ReactBSAlert>
    )
  }

  async function updateCustomer(data) {
    const original = await DataStore.query(Customer, data.id);

    await DataStore.save(
      Customer.copyOf(original, updated => {
        updated.name = data.name;
        updated.surname = data.surname;
        updated.alias = data.alias;
        updated.telephone = data.telephone;
        updated.email = data.email;
        // TODO: complete update logic
      })
    );
  }

  const setCustomerView = (customerId) => {
    props.history.push('/admin/customer?id='+customerId)
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectColumn: true,
    // bgColor: '#00BFFF',
    onSelect: (row, isSelect, rowIndex, e) => 
    {
      console.log(row);
      // console.log(isSelect);
      // console.log(rowIndex);
      // console.log(e);
      setCustomerView(row.id);
    },
  };
  
  return (
    <>
      {alert}
      <TipsHeader section="Clientes de Ocaso" subsection="Listado" 
        addAction={() => setModalAdd(true)} 
        addActionText="Añadir cliente"
        importAction={() => setModalImport(true)} 
        importActionText="Importar clientes"

        />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Card elevation={0}>
              <CardHeader
                subheader={
                  <>
                    <Box
                      component={Typography}
                      variant="h2"
                      marginBottom="0!important"
                    >
                      <Box component="span">Clientes de Ocaso</Box>
                    </Box>
                    <Box
                      component="p"
                      fontSize=".875rem"
                      marginBottom="0"
                      marginTop="0"
                      lineHeight="1.7"
                      fontWeight="300"
                    >
                      Listado de clientes de Ocaso en la base de datos.
                    </Box>
                  </>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>

              <ToolkitProvider
                data={customers}
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "ID",
                    sort: false,
                    hidden: true
                  },
                  {
                    dataField: "name",
                    text: "Nombre",
                    sort: true,
                  },
                  {
                    dataField: "surname",
                    text: "Apellido",
                    sort: true,
                  },
                  {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                  },
                  {
                    dataField: "telephone",
                    text: "Teléfono",
                    sort: true,
                  },
                  // {65y
                  //   dataField: "customerID",
                  //   text: "ID de Ocaso",
                  //   sort: true,
                  // },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive table-click">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Buscar:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=" "
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      selectRow={ selectRow }
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <CustomerForm open={modalAdd} openAction={setModalAdd} addNewAction={async (data) => await createCustomer(data)} />
      <CustomerForm bulkImport={true} open={modalImport} openAction={setModalImport} addNewAction={async (data) => await importCustomers(data)} />
    </>
  );
};

export default CustomerList;

